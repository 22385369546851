import { useDispatch, useSelector } from 'react-redux'
import {
  FantasyPosition,
  FunFacts,
  League,
  PlayerPickGrades,
  TeamAffinityAversionSettings,
  PlayerNextRoundAvailability,
  Drafter,
  DraftType,
  FantasyProvider,
} from '@pff-consumer/schema'
import { PlayerPosition } from '@pro-football-focus/pffkit-js-core'
import { LiveDraftPick } from '@pff-consumer/schema/fantasy-live-draft/picks'
import type { Keeper } from '@pff-consumer/schema/fantasy-live-draft/keeper'
import { Matchup, MatchupPlayer } from '@pff-consumer/schema/sdk/matchups'
import {
  fantasySelectors,
  fantasySlice,
  DraftRoomSettingsPositions,
  RankingsEntity,
  WeeklyRankingsEntity,
} from './fantasy.slice'

export const fantasyHooks = {
  useSelectedInSeasonActiveTabIndex: () => useSelector(fantasySelectors.getInSeasonActiveTabIndex),
  useSelectedInSeasonWeeklyRankingsPosition: () =>
    useSelector(fantasySelectors.getInSeasonWeeklyRankingsSelectedPosition),
  useSelectedInSeasonStartSitPosition: () => useSelector(fantasySelectors.getInSeasonStartSitSelectedPosition),
  useSelectedPositions: () => useSelector(fantasySelectors.getSelectedPosition),
  useSelectedLeagueType: () => useSelector(fantasySelectors.getSelectedLeagueType),
  useSelectedInSeasonWeeklyRankingsScoringType: () =>
    useSelector(fantasySelectors.getInSeasonWeeklyRankingsSelectedScoringType),
  useSelectedInSeasonStartSitScoringType: () => useSelector(fantasySelectors.getInSeasonStartSitSelectedScoringType),
  useSelectedScoringType: () => useSelector(fantasySelectors.getSelectedScoringType),
  useSelectedInSeasonWeeklyRankingsRanker: () => useSelector(fantasySelectors.getInSeasonWeeklyRankingsSelectedRanker),
  useSelectedInSeasonStartSitRanker: () => useSelector(fantasySelectors.getInSeasonStartSitRanker),
  useSelectedRanker: () => useSelector(fantasySelectors.getSelectedRanker),
  useSelectedInSeasonWeeklyRankingsTags: () => useSelector(fantasySelectors.getInSeasonWeeklyRankingsSelectedTags),
  useSelectedInSeasonStartSitTags: () => useSelector(fantasySelectors.getInSeasonStartSitSelectedTags),
  useSelectedTags: () => useSelector(fantasySelectors.getSelectedTags),
  useInSeasonWeeklyUnfilteredRankings: () => useSelector(fantasySelectors.getInSeasonWeeklyUnfilteredRankings),
  useUnfilteredRankings: () => useSelector(fantasySelectors.getUnfilteredRankings),
  useInSeasonWeeklyRankings: () => useSelector(fantasySelectors.getInSeasonWeeklyRankings),
  useInSeasonStartSitRankings: () => useSelector(fantasySelectors.getInSeasonStartSitRankings),
  useMutatedRankings: () => useSelector(fantasySelectors.getMutatedRankings),
  useRankings: () => useSelector(fantasySelectors.getRankings),
  useSelectedFilters: () => useSelector(fantasySelectors.getAllFilters),
  usePrunedSelectedFilters: (listOfFilterstoBePruned: string[]) =>
    useSelector(fantasySelectors.getPrunedFilters(listOfFilterstoBePruned)),
  useSelectedInSeasonWeeklyRankingsFilters: () => useSelector(fantasySelectors.getAllInSeasonWeeklyRankingsFilters),
  useSelectedInSeasonStartSitFilters: () => useSelector(fantasySelectors.getAllInSeasonStartSitFilters),
  useSelectedPlayerToCompareWith: (playerId: number) =>
    useSelector(fantasySelectors.getSelectedPlayerToCompareWith(playerId)),
  useDraftedPlayerInfo: (playerIds: string[]) => useSelector(fantasySelectors.getDraftedPlayerInfo(playerIds)),
  useSelectedPlayerToCompareId: (playerId: number) =>
    useSelector(fantasySelectors.getSelectedPlayerToCompareId(playerId)),
  useGetPlayerCountFilteredByTags: (tags: string[]) => useSelector(fantasySelectors.getPlayerCountFilteredByTags(tags)),
  useGetSortedRankings: () => useSelector(fantasySelectors.getSortedRankings),
  useGetPlayerCountFilteredByTagsForStandaloneRankings: (tags: string[]) =>
    useSelector(fantasySelectors.getPlayerCountFilteredByTagsForStandaloneRankings(tags)),
  useSelectedSort: () => useSelector(fantasySelectors.getSort),
  useSearchTerm: () => useSelector(fantasySelectors.getSearchTerm),
  useInSeasonWeeklyRankingsSearchTerm: () => useSelector(fantasySelectors.getInSeasonWeeklyRankingsSearchTerm),
  useIsSelectedInSeasonLeagueSuperflex: () => useSelector(fantasySelectors.getIsSelectedInSeasonLeagueSuperflex),
  useInSeasonStartSitSearchTerm: () => useSelector(fantasySelectors.getInSeasonStartSitSearchTerm),
  useHasValidStartSitSelection: () => useSelector(fantasySelectors.hasValidStartSitSelection),
  useSelectedStartSitPlayerAId: () => useSelector(fantasySelectors.getStartSitPlayerAId),
  useSelectedStartSitPlayerBId: () => useSelector(fantasySelectors.getStartSitPlayerBId),
  useSelectedStartSitPlayerAPosition: () => useSelector(fantasySelectors.getStartSitPlayerAPosition),
  useSelectedStartSitPlayerRankings: () => useSelector(fantasySelectors.getSelectedStartSitPlayerRankings),
  useGetInSeasonSelectedLeague: () => useSelector(fantasySelectors.getInSeasonSelectedLeague),
  useGetNonManualSyncedLeagues: () => useSelector(fantasySelectors.getNonManualSyncedLeagues),
  useGetNonManualSyncedLeague: () => useSelector(fantasySelectors.getNonManualSyncedLeague),
  useInSeasonMyTeamIsOptimizeToggleOn: () => useSelector(fantasySelectors.getInSeasonMyTeamIsOptimizeToggleOn),
  useGetInSeasonMyTeamMatchup: () => useSelector(fantasySelectors.getInSeasonMyTeamMatchup),
  useCurrentGameWeek: () => useSelector(fantasySelectors.getCurrentGameWeek),
  useFunFacts: () => useSelector(fantasySelectors.getFunFacts),
  useDraftPicks: () => useSelector(fantasySelectors.getDraftPicks),
  useUserDraftPicks: () => useSelector(fantasySelectors.getUserPicks),
  usePositionsAllowedToBeDrafted: () => useSelector(fantasySelectors.getPositionsAllowedToBeDrafted),
  useDraftSuggestions: () => useSelector(fantasySelectors.getDraftSuggestions),
  usePlayerPickGrades: () => useSelector(fantasySelectors.getPlayerPickGrades),
  usePlayerNextRoundAvailability: () => useSelector(fantasySelectors.getPlayerNextRoundAvailability),
  useDraftRoomSelectedSuggestedPlayerIndex: () => useSelector(fantasySelectors.getSelectedSuggestedPlayerIndex),
  useDraftSuggestionsLoading: () => useSelector(fantasySelectors.getDraftRoomIsSuggestionsLoading),
  useDraftRoomDrafting: () => useSelector(fantasySelectors.getDraftRoomIsDrafting),
  useGetIsUserOnTheClock: () => useSelector(fantasySelectors.getIsUserOnTheClock),
  useGetIsLiveDraftMode: () => useSelector(fantasySelectors.getIsLiveDraftMode),
  useGetDraftPickPlayers: () => useSelector(fantasySelectors.getDraftPickPlayers),
  useGetDraftPickOrder: () => useSelector(fantasySelectors.getDraftPickOrder),
  useGetFantasyPlayersInLeague: () => useSelector(fantasySelectors.getFantasyPlayersInLeague),
  useGetIsLastDraftRound: () => useSelector(fantasySelectors.getIsLastDraftRound),
  useGetCurrentRound: () => useSelector(fantasySelectors.getCurrentRound),
  useShouldShowLaggingSuggestionsBanner: () => useSelector(fantasySelectors.getShouldShowLaggingSuggestionsBanner),
  useGetRoster: () => useSelector(fantasySelectors.getRoster),
  useGetDraftRoomStarterMap: () => useSelector(fantasySelectors.getDraftRoomStarterMap),
  useGetDraftRoomRounds: () => useSelector(fantasySelectors.getDraftRoomRounds),
  useGetDraftRoomUserPickNumber: () => useSelector(fantasySelectors.getDraftRoomUserPickNumber),
  useGetDraftRoomScoringType: () => useSelector(fantasySelectors.getDraftRoomScoringType),
  useGetDraftType: () => useSelector(fantasySelectors.getDraftType),
  useGetRosterStartersCount: () => useSelector(fantasySelectors.getRosterStartersCount),
  useGetDraftRoomLeagueSettings: () => useSelector(fantasySelectors.getDraftRoomLeagueSettings),
  useGetSyncedLeague: () => useSelector(fantasySelectors.getSyncedLeague),
  useGetSyncedLeagueProvider: () => useSelector(fantasySelectors.getSyncedLeagueProvider),
  useGetSyncedLeagues: () => useSelector(fantasySelectors.getSyncedLeagues),
  useGetManualLeagueSettings: () => useSelector(fantasySelectors.getManualLeagueSettings),
  useGetManualLeagueSettingsNumberOfTeams: () => useSelector(fantasySelectors.getManualLeagueSettingsNumberOfTeams),
  useGetDraftRoomUserPick: () => useSelector(fantasySelectors.getDraftRoomUserPick),
  useGetManualLeagueSettingsNumberOfRounds: () => useSelector(fantasySelectors.getManualLeagueSettingsNumberOfRounds),
  useGetManualLeagueSettingsStarters: () => useSelector(fantasySelectors.getManualLeagueSettingsStarters),
  useGetMdsEndingEarly: () => useSelector(fantasySelectors.getMdsEndingEarly),
  useGetMdsResultsRequestBody: () => useSelector(fantasySelectors.getMdsResultsRequestBody),
  useGetIsMockDraftEnded: () => useSelector(fantasySelectors.getIsMockDraftEnded),
  useGetIsDraftRoomUserStrategyLateQB: () => useSelector(fantasySelectors.getIsDraftRoomUserStrategyLateQB),
  useGetIsDraftRoomUserStrategyStacking: () => useSelector(fantasySelectors.getIsDraftRoomUserStrategyStacking),
  useGetIsDraftRoomUserStrategyZeroRB: () => useSelector(fantasySelectors.getIsDraftRoomUserStrategyZeroRB),
  useGetDraftRoomUserStrategyLabel: () => useSelector(fantasySelectors.getDraftRoomUserStrategyLabel),
  useDraftRoomLeagueSettings: () => useSelector(fantasySelectors.getDraftRoomLeagueSettings),
  useGetDraftRoomNumberOfTeams: () => useSelector(fantasySelectors.getDraftRoomNumberOfTeams),
  useCPUTeamAffinityFromLeagueSettings: () => useSelector(fantasySelectors.getCPUTeamAffinityFromLeagueSettings),
  useCPUTeamAversionFromLeagueSettings: () => useSelector(fantasySelectors.getCPUTeamAversionFromLeagueSettings),
  useGetUnfilteredRankings: () => useSelector(fantasySelectors.getUnfilteredRankings),
  useGetRankingsUpdatedTime: () => useSelector(fantasySelectors.getRankingsUpdatedTime),
  useDraftRoomSuggestedPlayerIds: () => useSelector(fantasySelectors.getDraftRoomSuggestedPlayerIds),
  useDraftRoomInitialLoad: () => useSelector(fantasySelectors.getDraftRoomInitialLoad),
  useGetLeagueKeeperExtractedIds: () => useSelector(fantasySelectors.getLeagueKeeperExtractedIds),
  useIsPlayerDrafted: (playerId: number, position: FantasyPosition) =>
    useSelector(fantasySelectors.getIsPlayerDrafted(playerId, position)),
  useHasPlayerAnimatedIn: (playerId: number) => useSelector(fantasySelectors.getHasPlayerAnimatedIn(playerId)),
  useGetDraftRoomIsInSuperflexMode: () => useSelector(fantasySelectors.getDraftRoomIsInSuperflexMode),
  useGetIsMutatingRankings: () => useSelector(fantasySelectors.getIsMutatingRankings),
  useGetSpecificPlayerNextRoundAvailability: (playerId: number) =>
    useSelector(fantasySelectors.getSpecificPlayerNextRoundAvailability(playerId)),
  useGetSpecificPlayerPickGrade: (playerId: number, position: FantasyPosition) =>
    useSelector(fantasySelectors.getSpecificPlayerPickGrade(playerId, position)),
  useGetInSeasonMyTeamPlayerRanking: (playerId: number) =>
    useSelector(fantasySelectors.getInSeasonMyTeamPlayerRankings(playerId)),
  useDraftRoomCanShowDraftedPlayers: () => useSelector(fantasySelectors.getDraftRoomCanShowDraftedPlayers),
  useGetLiveDraftPicks: () => useSelector(fantasySelectors.getLiveDraftPicks),
  useGetLiveDraftStatus: () => useSelector(fantasySelectors.getLiveDraftStatus),
  useGetLiveDraftHasContextualMessage: () => useSelector(fantasySelectors.getLiveDraftHasContextualMessage),
  useGetDraftRoomNumberOfPicks: () => useSelector(fantasySelectors.getDraftRoomNumberOfPicks),
  useGetUserTeamName: () => useSelector(fantasySelectors.getSyncedLeagueTeamName),
  useGetLeagueDrafters: () => useSelector(fantasySelectors.getLeagueDrafters),
  useGetLiveDraftPicksCount: () => useSelector(fantasySelectors.getLiveDraftPicksCount),
  useGetLastDraftedPlayerPickTime: () => useSelector(fantasySelectors.getLastDraftedPlayerPickTime),
  useGetCurrentRoundPickNumber: () => useSelector(fantasySelectors.getCurrentRoundPickNumber),
  useGetLastPickInfo: () => useSelector(fantasySelectors.getLastPickInfo),
  useGetCurrentPickPlayerName: () => useSelector(fantasySelectors.getCurrentPickPlayerName),
  useGetNextPickPlayerName: () => useSelector(fantasySelectors.getNextPickPlayerName),
  useGetSyncedLeagueName: () => useSelector(fantasySelectors.getSyncedLeagueName),
  useGetDraftDate: () => useSelector(fantasySelectors.getDraftDate),
  useGetDraftTimePerPick: () => useSelector(fantasySelectors.getDraftTimePerPick),
  useGetLiveDraftCurrentRound: () => useSelector(fantasySelectors.getLiveDraftCurrentRound),
  useGetDraftRoomLeagueIsYahoo: () => useSelector(fantasySelectors.getDraftRoomLeagueIsYahoo),
  useGetInSeasonRosterPlayerIds: () => useSelector(fantasySelectors.getInSeasonRosterPlayerIds),
  useGetInSeasonRosterPlayers: () => useSelector(fantasySelectors.getInSeasonRosterPlayers),
  useInSeasonIsLeagueUnselected: () => useSelector(fantasySelectors.getInSeasonIsLeagueUnselected),
  useInSeasonMyTeamIsMatchupLoading: () => useSelector(fantasySelectors.getInSeasonMyTeamIsMatchupLoading),
  useInSeasonMyTeamRosterMatchup: () => useSelector(fantasySelectors.getInSeasonMyTeamRosterMatchup),
  useInSeasonMyTeamPlayerGradeMap: () => useSelector(fantasySelectors.getInSeasonMyTeamPlayerGradeMap),
  useGetInSeasonMyTeamSelectedMatchup: () => useSelector(fantasySelectors.getInSeasonMyTeamSelectedMatchup),
  useGetInSeasonMyTeamSelectedOpponent: () => useSelector(fantasySelectors.getInSeasonMyTeamSelectedOpponent),
  useIsInSeasonSyncLeagueModalVisible: () => useSelector(fantasySelectors.getIsInSeasonSyncLeagueModalVisible),
  useInSeasonMatchupsSelectedPosition: () => useSelector(fantasySelectors.getInSeasonMatchupsSelectedPosition),
  useLeagueSyncFantasyProvider: () => useSelector(fantasySelectors.getLeagueSyncFantasyProvider),
  useLeagueSyncActiveStep: () => useSelector(fantasySelectors.getLeagueSyncActiveStep),
  useLeagueSyncLeagueName: () => useSelector(fantasySelectors.getLeagueSyncLeagueName),
  useLeagueSyncUid: () => useSelector(fantasySelectors.getLeagueSyncUid),
  useGetSyncedFantasyLeagueAnalytics: () => useSelector(fantasySelectors.getSyncedFantasyLeagueAnalytics),
  useGetManualFantasyLeagueAnalytics: () => useSelector(fantasySelectors.getManualFantasyLeagueAnalytics),
  useGetLDAPlayerDraftedAnalytics: () => useSelector(fantasySelectors.getLDAPlayerDraftedAnalytics),
  useGetLDARoundStartedAnalytics: () => useSelector(fantasySelectors.getLDARoundStartedAnalytics),

  useFantasyActions: () => {
    const dispatch = useDispatch()

    return {
      fetchNextPickInfo: () => {
        dispatch(fantasySlice.actions.fetchNextPickInfo())
      },
      removePlayerToCompareWith: (payload: { id: number | undefined }) => {
        dispatch(fantasySlice.actions.removePlayerToCompareWith(payload))
      },
      setPlayerToCompareWith: (payload: { id: number; selectedPlayerId: number }) => {
        dispatch(fantasySlice.actions.setPlayerToCompareWith(payload))
      },
      setInSeasonActiveTabIndex: (tabIndex: number) => {
        dispatch(fantasySlice.actions.setInSeasonActiveTabIndex(tabIndex))
      },
      setInSeasonWeeklyRankingsSearchTerm: (searchTerm: string) => {
        dispatch(fantasySlice.actions.setInSeasonWeeklyRankingsSearchTerm(searchTerm))
      },
      setInSeasonStartSitSearchTerm: (searchTerm: string) => {
        dispatch(fantasySlice.actions.setInSeasonStartSitSearchTerm(searchTerm))
      },
      setSearchTerm: (searchTerm: string) => {
        dispatch(fantasySlice.actions.setSearchTerm(searchTerm))
      },
      updateInSeasonIsLeagueUnselected: (payload: boolean) => {
        dispatch(fantasySlice.actions.updateInSeasonIsLeagueUnselected(payload))
      },
      updateInSeasonWeeklyRankings: (rankings: unknown[]) => {
        dispatch(fantasySlice.actions.updateInSeasonWeeklyRankings(rankings))
      },
      updateInSeasonStartSitRankings: (rankings: unknown[]) => {
        dispatch(fantasySlice.actions.updateInSeasonStartSitRankings(rankings))
      },
      updateInSeasonWeeklyUnfilteredRankings: (rankings: WeeklyRankingsEntity[]) => {
        dispatch(fantasySlice.actions.updateInSeasonWeeklyUnfilteredRankings(rankings))
      },
      updateUnfilteredRankings: (rankings: RankingsEntity[]) => {
        dispatch(fantasySlice.actions.updateUnfilteredRankings(rankings))
      },
      updateRankings: (rankings: RankingsEntity[]) => {
        dispatch(fantasySlice.actions.updateRankings(rankings))
      },
      updateInSeasonWeeklyRankingsFilterPosition: (position: string) => {
        dispatch(fantasySlice.actions.updateInSeasonWeeklyRankingsFilterPosition(position))
      },
      updateInSeasonStartSitFilterPosition: (position: string) => {
        dispatch(fantasySlice.actions.updateInSeasonStartSitFilterPosition(position))
      },
      updateFilterPosition: (positions: string[]) => {
        dispatch(fantasySlice.actions.updateFilterPosition(positions))
      },
      updateInSeasonWeeklyRankingsFilterTags: (selected: string[]) => {
        dispatch(fantasySlice.actions.updateInSeasonWeeklyRankingsFilterTags(selected))
      },
      updateInSeasonStartSitFilterTags: (selected: string[]) => {
        dispatch(fantasySlice.actions.updateInSeasonStartSitFilterTags(selected))
      },
      updateFilterTags: (selected: string[]) => {
        dispatch(fantasySlice.actions.updateFilterTags(selected))
      },
      updateInSeasonWeeklyRankingsFilterRanker: (selected: string) => {
        dispatch(fantasySlice.actions.updateInSeasonWeeklyRankingsFilterRanker(selected))
      },
      updateInSeasonStartSitFilterRanker: (selected: string) => {
        dispatch(fantasySlice.actions.updateInSeasonStartSitFilterRanker(selected))
      },
      updateSyncedLeagues: (leagues: Array<League>) => {
        dispatch(fantasySlice.actions.updateSyncedLeagues(leagues))
      },
      updateInSeasonSelectedLeague: (league: League | null) => {
        dispatch(fantasySlice.actions.updateInSeasonSelectedLeague(league))
      },
      updateFilterRanker: (selected: string[]) => {
        dispatch(fantasySlice.actions.updateFilterRanker(selected))
      },
      updateInSeasonWeeklyRankingsFilterScoringType: (selected: string) => {
        dispatch(fantasySlice.actions.updateInSeasonWeeklyRankingsFilterScoringType(selected))
      },
      updateInSeasonStartSitFilterScoringType: (selected: string) => {
        dispatch(fantasySlice.actions.updateInSeasonStartSitFilterScoringType(selected))
      },
      updateFilterScoringType: (selected: string) => {
        dispatch(fantasySlice.actions.updateFilterScoringType(selected))
      },
      updateFilterLeagueType: (selected: string) => {
        dispatch(fantasySlice.actions.updateFilterLeagueType(selected))
      },
      updateSort: (fantasySortBy: string) => {
        dispatch(fantasySlice.actions.updateSorting(fantasySortBy))
      },
      updateStartSitPlayerAId: (payload: number | null) => {
        dispatch(fantasySlice.actions.updateStartSitPlayerAId(payload))
      },
      updateStartSitPlayerBId: (payload: number | null) => {
        dispatch(fantasySlice.actions.updateStartSitPlayerBId(payload))
      },
      updateCurrentGameWeek: (payload: number) => {
        dispatch(fantasySlice.actions.updateCurrentGameWeek(payload))
      },
      updateFunFacts: (payload: FunFacts[]) => {
        dispatch(fantasySlice.actions.updateFunFacts(payload))
      },
      updateStartSitPlayerAPosition: (payload: FantasyPosition) => {
        dispatch(fantasySlice.actions.updateStartSitPlayerAPosition(payload))
      },
      updateAllLiveDraftPicks: (payload: LiveDraftPick[]) => {
        dispatch(fantasySlice.actions.updateAllLiveDraftPicks(payload))
      },
      updateLiveDraftPick: (payload: LiveDraftPick) => {
        dispatch(fantasySlice.actions.updateLiveDraftPick(payload))
      },
      updateWebSocketReceivedMessage: (payload: boolean) => {
        dispatch(fantasySlice.actions.updateWebSocketReceivedMessage(payload))
      },
      updateDraftRoomPlayerPickGrades: (payload: PlayerPickGrades) => {
        dispatch(fantasySlice.actions.updateDraftRoomPlayerPickGrades(payload))
      },
      updateDraftRoomPlayerNextRoundAvailability: (payload: PlayerNextRoundAvailability) => {
        dispatch(fantasySlice.actions.updateDraftRoomPlayerNextRoundAvailability(payload))
      },
      updateDraftRoomSelectedSuggestedPlayerIndex: (payload: number) => {
        dispatch(fantasySlice.actions.updateDraftRoomSelectedSuggestedPlayerIndex(payload))
      },
      updateMdsDraftPicks: (payload: string) => {
        dispatch(fantasySlice.actions.updateMdsDraftPicks(payload))
      },
      updateMdsEndingEarly: (payload: boolean) => {
        dispatch(fantasySlice.actions.updateMdsEndingEarly(payload))
      },
      updateDraftRoomCurrentRound: (payload: number) => {
        dispatch(fantasySlice.actions.updateDraftRoomCurrentRound(payload))
      },
      updateUserPicks: (payload: string) => {
        dispatch(fantasySlice.actions.updateUserPicks(payload))
      },
      updatePositionsAllowedToBeDrafted: (payload: string[]) => {
        dispatch(fantasySlice.actions.updatePositionsAllowedToBeDrafted(payload))
      },
      updateIsUserOnTheClock: (payload: boolean) => {
        dispatch(fantasySlice.actions.updateIsUserOnTheClock(payload))
      },
      updateDraftRoomLeagueSettings: (payload: unknown) => {
        dispatch(fantasySlice.actions.updateDraftRoomLeagueSettings(payload))
      },
      updateDraftRoomSuggestions: (payload: unknown) => {
        dispatch(fantasySlice.actions.updateDraftRoomSuggestions(payload))
      },
      updateSyncedLeague: (payload: League | null) => {
        dispatch(fantasySlice.actions.updateSyncedLeague(payload))
      },
      updateManualLeagueSettings: (payload: string) => {
        dispatch(fantasySlice.actions.updateManualLeagueSettings(payload))
      },
      updateManualSettingsNumberOfTeams: (payload: number) => {
        dispatch(fantasySlice.actions.updateManualSettingsNumberOfTeams(payload))
      },
      updateManualSettingsUserPick: (payload: number) => {
        dispatch(fantasySlice.actions.updateManualSettingsUserPick(payload))
      },
      updateDraftRoomSettingsUserPick: (payload: number) => {
        dispatch(fantasySlice.actions.updateDraftRoomSettingsUserPick(payload))
      },
      updateManualSettingsNumberOfRounds: (payload: number) => {
        dispatch(fantasySlice.actions.updateManualSettingsNumberOfRounds(payload))
      },
      updateManualLeagueSettingsStarters: (payload: { position: DraftRoomSettingsPositions; value: number }) => {
        dispatch(fantasySlice.actions.updateManualLeagueSettingsStarters(payload))
      },
      updateManualLeagueSettingsDraftType: (payload: DraftType) => {
        dispatch(fantasySlice.actions.updateManualLeagueSettingsDraftType(payload))
      },
      updateManualLeagueSettingsScoringType: (payload: 'NON-PPR' | 'HALF PPR' | 'PPR') => {
        dispatch(fantasySlice.actions.updateManualLeagueSettingsScoringType(payload))
      },
      updateDraftRoomUserStrategyZeroRB: (payload: boolean) => {
        dispatch(fantasySlice.actions.updateDraftRoomUserStrategyZeroRB(payload))
      },
      updateDraftRoomUserStrategyLateQB: (payload: boolean) => {
        dispatch(fantasySlice.actions.updateDraftRoomUserStrategyLateQB(payload))
      },
      updateDraftRoomUserStrategyStacking: (payload: boolean) => {
        dispatch(fantasySlice.actions.updateDraftRoomUserStrategyStacking(payload))
      },
      updateCPUTeamAffinityForLeagueSettings: (payload: TeamAffinityAversionSettings) => {
        dispatch(fantasySlice.actions.updateCPUTeamAffinityForLeagueSettings(payload))
      },
      updateCPUTeamAversionForLeagueSettings: (payload: TeamAffinityAversionSettings) => {
        dispatch(fantasySlice.actions.updateCPUTeamAversionForLeagueSettings(payload))
      },
      updateCPUTeamAversionAndAffinitySize: (payload: number) => {
        dispatch(fantasySlice.actions.updateCPUTeamAversionAndAffinitySize(payload))
      },
      updateDraftRoomInitialLoad: (payload: boolean) => {
        dispatch(fantasySlice.actions.updateDraftRoomInitialLoad(payload))
      },
      updatePlayerHasAnimated: (payload: number) => {
        dispatch(fantasySlice.actions.updatePlayerHasAnimated(payload))
      },
      updateDraftRoomShowDraftedPlayers: (payload: boolean) => {
        dispatch(fantasySlice.actions.updateDraftRoomShowDraftedPlayers(payload))
      },
      updateLiveDraftStatus: (payload: string) => {
        dispatch(fantasySlice.actions.updateLiveDraftStatus(payload))
      },
      updateLeagueDrafters: (payload: Drafter[]) => {
        dispatch(fantasySlice.actions.updateLeagueDrafters(payload))
      },
      updateLeagueKeepers: (payload: Keeper[]) => {
        dispatch(fantasySlice.actions.updateLeagueKeepers(payload))
      },
      updateDraftPickOrder: () => {
        dispatch(fantasySlice.actions.updateDraftPickOrder())
      },
      updateInSeasonMyTeamIsOptimizeToggleOn: (payload: boolean) => {
        dispatch(fantasySlice.actions.updateInSeasonMyTeamIsOptimizeToggleOn(payload))
      },
      setInSeasonMyTeamMatchupOpponent: (payload: MatchupPlayer | null) => {
        dispatch(fantasySlice.actions.setInSeasonMyTeamMatchupOpponent(payload))
      },
      setInSeasonMyTeamSelectedMatchup: (payload: Matchup) => {
        dispatch(fantasySlice.actions.updateInSeasonMyTeamSelectedMatchup(payload))
      },
      updateIsInSeasonSyncLeagueModalVisible: (payload: boolean) => {
        dispatch(fantasySlice.actions.updateIsInSeasonSyncLeagueModalVisible(payload))
      },
      updateInSeasonMyTeamSelectedMatchup: (payload: Matchup) => {
        dispatch(fantasySlice.actions.updateInSeasonMyTeamSelectedMatchup(payload))
      },
      updateInSeasonMyTeamSelectedOpponent: (payload: Matchup) => {
        dispatch(fantasySlice.actions.updateInSeasonMyTeamSelectedOpponent(payload))
      },
      updateInSeasonMatchupsSelectedPosition: (payload: PlayerPosition) => {
        dispatch(fantasySlice.actions.updateInSeasonMatchupsSelectedPosition(payload))
      },
      updateLeagueSyncFantasyProvider: (payload: FantasyProvider | null) => {
        dispatch(fantasySlice.actions.setLeagueSyncFantasyProvider(payload))
      },
      updateLeagueSyncActiveStep: (payload: number) => {
        dispatch(fantasySlice.actions.setLeagueSyncActiveStep(payload))
      },
      updateLeagueSyncLeagueName: (payload: string | null) => {
        dispatch(fantasySlice.actions.setLeagueSyncLeagueName(payload))
      },
      updateLeagueSyncUid: (payload: string | null) => {
        dispatch(fantasySlice.actions.setLeagueSyncLeagueUid(payload))
      },
    }
  },
}
